import * as React from "react";
import Header from "../pages/Header";
import { graphql } from "gatsby";
import "./BlogPage.css";
import Sidebar from "../pages/Sidebar";
import "../pages/Header.css";
import { Link } from "gatsby";
import Footer from "../pages/Footer"
import Share from "../pages/Share";

const BlogPage = ({
  data
}) => {
  return (
    <div className="blogPage">
      <div className="headerBlogPage">
        <Header />
      </div>
      <div className="blogPageDetails">
        <div className="blogPage_backgroundImage">
          <div className="blogPage_title">
            <h1>Perspective</h1>
          </div>
          <Link className="linkBlogPage" to="/Perspective">
            <div className="paragraph_blog">
              <p className="blogPage_p_tag">
                Home &nbsp; {">"} &nbsp; Perspectives&nbsp;&nbsp;&nbsp;&nbsp;{">"}
                &nbsp;&nbsp;{data.gcms.perspectivesBlog.blogTitle}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <button className="nav_button"> &nbsp;&nbsp;&nbsp;&nbsp;&larr; &nbsp; Back &nbsp;&nbsp;&nbsp;&nbsp;  </button>

            </div>
          </Link>


          <div className="blogContent">
            <div className="blogDetails">
              <h2>{data.gcms.perspectivesBlog.blogTitle}</h2>

              <div className="authorDate">
                <p>{data.gcms.perspectivesBlog.authorName}</p>
                <p>{data.gcms.perspectivesBlog.dateOfPublishing}</p>
              </div>

              <img
                className="blogPageImage"
                src={data.gcms.perspectivesBlog.coverImage.url}
              />
              <div
                className="content_align"
                dangerouslySetInnerHTML={{
                  __html: data.gcms.perspectivesBlog.blogContent.html,
                }}
              />
              <hr />
              <Share title={data.gcms.perspectivesBlog.blogTitle}
                  author = {data.gcms.perspectivesBlog.authorName}
                  timestamp={data.gcms.perspectivesBlog.dateOfPublishing}
                  slug={data.gcms.perspectivesBlog.slug}/>
              <hr />
            </div>
            <div className="blog_sidebar">
              {data.gcms.perspectivesBlogs.map((perspective) =>

                <Sidebar
                  image={perspective.coverImage.url}
                  title={perspective.blogTitle}
                  timestamp={perspective.dateOfPublishing}
                  slug={perspective.slug}
                />

              )}
            </div>
          </div>
          <div className="blogPageFooter">
            <hr />
            <Footer />
          </div>

        </div>
      </div>

    </div>
  );
};

export default BlogPage;

export const PageQuery = graphql`
  query BlogPageQuery($id: ID!) {
    gcms {
      perspectivesBlog(where: { id: $id }) {
        authorName
        blogTitle
        createdAt
        dateOfPublishing
        publishedAt
        slug
        coverImage {
          url(
            transformation: { image: { resize: { height: 300, width: 300 } } }
          )
        }
        blogContent {
          html
        }
      }

      perspectivesBlogs(stage: PUBLISHED) {
        authorName
        cardDescription
        dateOfPublishing
        slug
        blogContent {
          html
        }
        coverImage {
          url(transformation:{
  
          image:{
          resize:{
            width: 350,
            height:350
           
          }
          }})
        }
        blogTitle
      }


     

    }
  }
`;
